import toastMessage from "./Toast";
import { Profanity } from '@2toad/profanity';

const profanity = new Profanity();

const Validation = {

    isObscene(value) {
        return profanity.exists(value)  // If there are any matches, it's obscene
    },
    checkValidUsername(value) {

        let standards = [
            {
                message: "Between 1 to 50 characters",
                regex: /^.{1,50}$/
            },  
            {
                message: "Invalid Character Present: Only Lowercase, UpperCase, Numbers, Dot(.), Underscore(_) and Hyphen(-) are allowed",
                regex: /^[a-zA-Z0-9._-]+$/
            },
        ]

        return standards.filter((standard) => {
            return !String(value).match(standard.regex)
        }).map((standard) => standard.message).join(", ")
    },
    email(email, suppress = false) {

        let match = String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

        if(!match) {
            if(!suppress)
                toastMessage(email ? email + " is not a valid email." : "Email Input Cannot be Empty", "error");
            return false;
        }

        return true;
    },
    checkValidPassword(value) {
        let standards = [
            {
                message: "Between 8 to 30 characters",
                regex: /^.{8,30}$/
            }, 
            {
                message: "At Least One Lowercase Letter",
                regex: /(?=.*[a-z])/
            },
            {
                message: "At Least One Capital Letter",
                regex: /(?=.*[A-Z])/
            },
            {
                message: "At Least One Number",
                regex: /(?=.*\d)/
            },
            {
                message: "At Least One Special Character",
                regex: /[!@#$%^&*()|,.?"~`':;{}<>_\-+=]/
            }
        ]

        return standards.filter((standard) => {
            return !String(value).match(standard.regex)
        }).map((standard) => standard.message).join(", ")
    },
    checkValidPasswordTwo(one, value) {
        if(one !== value) {
            return "Passwords do not match"
        }
    },
    passwords(one, two) {
        if(!one) {
            toastMessage("Please Enter your Password in the First Password Field", "error");
            return false;
        }
        
        if(one !== two) {
            toastMessage("Passwords do not match", "error");
            return false;
        }
        return true;
    },
    debounce(func, delay) {
        let timeoutId;
        return function (...args) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    }
}

export default Validation;