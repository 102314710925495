import axios from "axios";

import toastMessage from "../../helpers/Toast";

export default class Users {

    static async createUser(data) {

        let response = await axios.post(
            process.env.REACT_APP_SERVER_URL + "/users", 
            data
        )

        if(response.data && response.data.warning) {
            toastMessage(response.data.warning, "warning");
            return;
        } else if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        } else if(response.data) {
            toastMessage("Your Account Has Been Created!", "success");
            localStorage.setItem("accessToken", response.data.token)
        }

        return response.data;
    }

    static async updateUser(data) {

        let response = await axios.patch(
            process.env.REACT_APP_SERVER_URL + "/users/update", 
            data,
            { headers: {
                    accessToken: localStorage.getItem("accessToken")
                } 
            }, 
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }
    
        return response.data;
    }

    static async updatePassword(data) {

        let response = await axios.patch(
            process.env.REACT_APP_SERVER_URL + "/users/password", 
            data
        )

        if(response?.data?.warning) {
            toastMessage(response.data.warning, "warning")
        } else if( response?.data?.error ) {
            toastMessage(response.data.error, "error");
        } else if(response?.data?.message){
            toastMessage(response?.data?.message, "success");
        }
        return response.data;
    }

    static async checkUniqueUsername(username) {

        let response = await axios.get(
            process.env.REACT_APP_SERVER_URL + "/users/checkUsername/" + username 
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }
    
        return response.data;
    }
}