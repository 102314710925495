import React, { useState, useEffect, useRef } from 'react'
import {useLocation, useNavigate} from 'react-router-dom';

import SignUp from './components/entry/SignUp'
import Login from './components/entry/Login'
import LandingPageBackground from "./components/entry/LandingPage/LandingPageBackground";
import LogoAndTitle from "../assets/svg/LogoAndTitle.svg"
import InstallAppButton from './components/atoms/InstallAppButton';

function Entry() {

  let navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const intervalRef = useRef(null);
  const [step, setStep] = useState(0);
  const [sending, setSending] = useState(0);
  const [email, setEmail] = useState("")
  const [hasSentTime, setHasSentTime] = useState(30);

  useEffect(() => {
    if(state?.step) {
      setStep(state.step);
    }
  }, [state])

  const onChangeSendingStatus = (signUpStatus) => {
    setSending(signUpStatus);
      setHasSentTime(30);

      // Clear any existing interval before setting a new one
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      intervalRef.current = setInterval(() => {
        if(signUpStatus === 3) {

          setHasSentTime((prevTimeLeft) => {
            let timeLeft = prevTimeLeft - 1;
      
            if (timeLeft <= 0) {
                clearInterval(intervalRef.current); // Stop the interval
                intervalRef.current = null;  // Stop the interval
                return 0; // Ensure it sets to 0 at the end
            }
            return timeLeft; // Update the state with the new time
          });
        } else {
          clearInterval(intervalRef.current); // Stop the interval
          intervalRef.current = null;
        }
    }, 1000);
  }

  return (
        <div className='Page Column PageCenter'>
            <LandingPageBackground />
            <InstallAppButton />
            <div className='LandingPageHeader'>
                <div className='SignContainer' onClick={() => navigate("/")}>
                    <img src={LogoAndTitle} alt="Logo" />
                </div>
            </div>
            {
              step === 1 ?
                <Login 
                  setStep={(val) => setStep(val)}
                /> :
                <SignUp 
                  setStep={(val) => setStep(val)}
                  setSending={(value) => onChangeSendingStatus(value)}
                  sending={sending}
                  setEmail={(value) => setEmail(value)}
                  email={email}
                  setHasSentTime={(value) => setHasSentTime(value)}
                  hasSentTime={hasSentTime}
                />
            }
        </div>
  )
}

export default Entry
