import React, {useState, useEffect} from 'react'
import axios from 'axios'

import toastMessage from "../../../helpers/Toast";
import Validation from '../../../helpers/Validation';

import Input from '../Input';
import Loading from "../atoms/Loading"

import X from "../../../assets/svg/X.svg"
import EmailSent from "../../../assets/svg/EmailSent.svg"

function SignUp(props) {

    async function onSignUp(e) {

        props.setSending(1);
    
        if(e)
            e.preventDefault();

        if(!Validation.email(props.email)) {
            props.setSending(0);
            return;
        }

        if(props.email) {
            props.setSending(2);
            let data = {email: props.email}
                
            axios.post(process.env.REACT_APP_SERVER_URL + '/users/signUp', data).then((resp) => {
                if(resp.data.error) {
                    toastMessage(resp.data.error, "error");
                    props.setSending(0)
                } else {
                    props.setSending(3);
                    toastMessage("An email has been sent to " + resp.data.email, "success");
                }
            })
        }
    }

    return (
        <>
            <div className='EntryContentsContainer'>
                <div className='FormTitle'>
                    <h1 className='tertiaryFont centerText'>Start Coding <br/>Now!</h1>
                </div>
                {
                    <>
                        {   (props.sending === 0 || props.sending === 1) &&
                            <form className='Form EntryForm' onSubmit={(e) => onSignUp(e)}>
                                <Input 
                                    tabIndex="1" 
                                    value={props.email} 
                                    setValue={(e) => props.setEmail(e?.trim())} 
                                    placeholder={"Enter your Email"} 
                                    label={"Email"}
                                />
                            </form>
                        }
                        {
                            (props.sending === 2) && 
                            <div className='Form SendingEmail'>
                                <Loading />
                            </div>
                        }
                        {
                            (props.sending === 3) &&
                            <div className='Form EmailSent'>
                                <div className='EmailSentSection'>
                                    <button className='BackButton'>
                                        <img className="backButtonSvg" src={X} alt="Back Arrow" onClick={() => {
                                        props.setSending(0);
                                    }}></img>
                                    </button>
                                    <div className='EmailSentText'>
                                        <p className='tertiaryFont'>The Sign Up Email has been SENT to</p> 
                                        <img src={EmailSent} alt={"Email Sent"} className='EmailSentIcon'/>
                                        <p className='tertiaryFont emailP'>{props.email}</p>
                                        <p className='SubParagraph'>Follow the Instructions to Create your WanderBuilds Account.</p>
                                        <p className='SubParagraph'>The Email might be in the Spam Folder.</p>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='LandingPageFooter'>
                            {
                                (props.sending < 2) ?
                                    <button className={props.sending ? 'button-disabled' : 'button-quart'} onClick={(e) => onSignUp(e)} disabled={props.sending !== 0}>
                                        <h2 className='tertiaryFont'>{props.sending === 1 ? "Validating Email..."  : props.sending === 2 ? "Verifying...": "Get Sign Up Email"}</h2>
                                    </button>
                                :
                                    props.sending === 2 ?
                                        <button className={'button-disabled'}>
                                            <h2 className='tertiaryFont'>Sending Email...</h2>
                                        </button>
                                        :
                                        <button 
                                            className={props.hasSentTime === 0 ? 'button-quart' : 'button-disabled'} 
                                            onClick={(e) => {
                                                props.setSending(0)
                                                onSignUp(e)
                                            }
                                            } 
                                            disabled={props.hasSentTime !== 0}
                                            >
                                            <h2 className='tertiaryFont'>{props.hasSentTime === 0 ? "Send Again" : "Send Again in " + props.hasSentTime + " seconds"}</h2>
                                        </button>
                            }
                            <h5 className='underlinedHover normal centerText grey' onClick={() => props.setStep(1)}>Already Have an Account? Log In</h5>
                        </div>
                    </>

                }
            </div>
          
        </>
    )
}

export default SignUp