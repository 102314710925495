import React, {useState, useEffect, useContext} from 'react'
import {useParams, useNavigate} from 'react-router-dom';
import * as esprima from 'esprima';
import { AuthContext } from '../helpers/AuthContext';

import Files from "../services/server/Files"
import Projects from "../services/server/Projects"

import Preview from './components/Preview';
import Menu from "./components/Menu";
import Header from './components/Header';
import FileView from './components/FileView';
import ProjectDetails from './components/ProjectDetails';
import Footer from "./components/Footer"
import { CODE_SNIPPET } from '../helpers/CodeFiller';

// SVGs
import MenuBurger from "../assets/svg/MenuBurger.svg";

import Home from '../assets/svg/Navigation/Home-active.svg';
import Start from "../assets/svg/Start.svg";
import Stop from "../assets/svg/Stop.svg";
import Studio from "../assets/svg/Navigation/Studio-active.svg";
import Code from "../assets/svg/Navigation/Code-active.svg";
import Create from "../assets/svg/Navigation/Create-active.svg";
import Play from "../assets/svg/Navigation/Play-active.svg";

import HomeInactive from "../assets/svg/Navigation/Home-inactive.svg";
import DetailsInactive from "../assets/svg/Navigation/Details-inactive.svg";
import StudioInactive from "../assets/svg/Navigation/Studio-inactive.svg";
import CreateInactive from "../assets/svg/Navigation/Create.svg";
import PlayInactive from "../assets/svg/Navigation/Play-inactive.svg";

// Images
import toastMessage from '../helpers/Toast';

import Loading from './components/atoms/Loading';

function CodingTool() {

  const navigate = useNavigate();
  const {entityId} = useParams();
  const {authState} = useContext(AuthContext);

  const [mode, setMode] = useState(false);
  const [scripts, setScripts] = useState([]);
  const [project, setProject] = useState(null);
  const [activeFile, setActiveFile] = useState(null);
  const [menu, setMenu] = useState(false);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saveCode, setSaveCode] = useState(true);

  useEffect(() => {
    if(authState?.status === true) {
      fetchData();
    } else {
      let file =  {
          id: 0,
          name: "index",
          language: "javascript",
          visible: true,
          projectId: null,
          location: null,
          status: "active",
          content: CODE_SNIPPET.basic
        }
      setProject(
        {
          name: "Intro",
          visibility: "private",
          image: null,
          userId: null,
          files: [
            file
          ]
        }
      )
      setActiveFile(
        file
      )
      setScripts([file])
      setLoading(false)
    }
  }, [entityId])

  useEffect(() => {
    if(mode === "details" && authState?.status === true) {
      fetchData();
    }
  }, [mode])

  async function fetchData() {
    let response = await Files.getFiles(entityId);

    if(response === undefined)
      navigate("/home");

    setProject(response);
    setScripts(response?.files);
    if(response?.files.length >= 1) {
      setActiveFile(response.files[0]);
    }
    setLoading(false);
  }
  
  useEffect(() => {
    if(mode === "preview" && scripts.length >= 1) {
      checkCode();
    }
    if(mode === "details") {
      setLoading(false);
    }
  }, [mode, scripts])

  const checkCode = async () => {
      let allErrors = [];
      await scripts.forEach( async (file) => {
        try {
          esprima.parseScript(file.content);
        } catch (error) {
          setMode(false);
          allErrors.push({file: file, message: error.message})
          console.error('Error occurred while linting:', error);
        }
        setError(allErrors);
      });
  }

  const openFile = async (value) => {
    let updatedFiles = [...scripts]
    updatedFiles.find((obj) => {return obj.name === value.name}).visible = true;
    setScripts(updatedFiles);
    setActiveFile(updatedFiles.find((obj) => {return obj.name === value.name}));
    setMenu(false);
  }

  const onDeleteFile = async (value) => {
    if(authState?.status) {
    let updatedFiles  = scripts.filter((item) => item.id !== value.id);
    setScripts(updatedFiles);
    setActiveFile(updatedFiles[0]);
    await Files.deleteFile(value.id);
    } else {
      navigate("/entry");
    }
  }

  const onSaveCode = async () => {
    if(authState?.status) {
      setSaveCode(false);
      for(let i = 0; i < scripts.length; i++) {
        if(scripts[i].altered) {
          let response = await Files.updateFile(scripts[i]);
          if(!response) {
            setSaveCode(true);
            return;
          }
        }
      }
      setScripts((scripts) => scripts.map((script) => ({...script, altered: false})));
      setActiveFile(scripts.find((obj) => {return obj.name === activeFile.name}))
      setSaveCode(true);
    } else {
      navigate("/entry");
    }
  }

  if(loading)
    return (
      <Loading type="page" />
    )

    return (
      <div className='Page column flex'>
      <>
        {!mode &&
        <Header
          icon={MenuBurger}
          onClickIcon={() => setMenu(!menu)}
          endIcons={[
            {
              type: "button",
              text: "SAVE",
              changeText: saveCode ? "SAVE" : "Saving Code...",
              changes: saveCode && scripts.find((script) => script.altered),
              onClick: onSaveCode,
            }
          ]}
        />}
        {!mode &&
        (
          menu ? 
          <Menu 
            scripts={scripts ? scripts.map((obj) => ({id: obj.id, name: obj.name})) : null} 
            openFile={(value) => openFile(value)}
            onDeleteFile={(value) => onDeleteFile(value)}
          /> 
          : 
          <FileView 
            activeFile={activeFile} 
            setActiveFile={(value) => setActiveFile(value)} 
            scripts={scripts} 
            setScripts={(scripts) => setScripts(scripts)} 
            error={error} 
            setError={() => setError([])}
            projectId={project.id}
          /> 
        )
        }

        {mode === "preview" && error.length < 1 && <Preview scripts={scripts}/> }

        {mode === "details" && <ProjectDetails project={project}/> }
      </>

      <Footer
        default = {2}
        items = {[
            {
              name: 'home',
              displayText: 'Home',
              style: {
                active: null,
                default: 'Icon-Reduced'
              },
              icon: {
                active: Home,
                inactive: HomeInactive
              }
            },
            ...scripts?.id ? 
            [
              {
                name: 'play',
                displayText: 'Play',
                style: {
                  active: null,
                  default: 'Icon-Reduced'
                },
                icon: {
                  active: Play,
                  inactive: PlayInactive
                },
                onClick: async () => {
                  let games = await Projects.getRecommendedProjectsId({entityIds: []});
                  if(games.length >= 1) {
                    navigate("/game/" + games[0].entityId);
                  } else {
                    toastMessage("No Games Available", "error")
                  }
                }
              },                 
            ]
            :
            [{
              name: 'details',
              displayText: mode === "details" ? 'Code' : 'Details',
              style: {
                active: null,
                default: 'Icon-Reduced'
              },
              icon: {
                active: mode === "details" ? Code : DetailsInactive,
                inactive: mode === "details" ? Code : DetailsInactive,
              },
              onClick: () => authState?.status ? (mode === "details" ? setMode(false) : setMode("details")) : navigate("/entry")

            }],
            ...scripts?.id ? 
            [
            {
              name: 'create',
              style: {
                active: null,
                default: 'Icon-Large'
              },
              icon: {
                active: Create,
                inactive: CreateInactive
              },
              onClick: () => {}
            }
            ] :
            [{
              name: 'start',
              style: {
                active: null,
                default: 'Icon-Large'
              },
              icon: {
                active: mode === "preview" ? Stop : Start,
                inactive: mode === "preview" ? Stop : Start
              },
              onClick: async () =>  {
                if(mode !== "preview") {
                  if(authState?.status) {
                    await onSaveCode();
                  }
                }
                  mode ? setMode(false) : setMode('preview')
              }
            }],
            {
              name: 'studio',
              displayText: 'Studio',
              style: {
                active: null,
                default: 'Icon'
              },
              icon: {
                active: Studio,
                inactive: StudioInactive
              },
              onClick: () => {
                authState?.status ?
                navigate("/home", {
                  state: {item: 3}
                })
                :
                navigate("/entry")
              }
            },
            {
              name: 'user',
              displayText: "You",
              style: {
                active: "UserActive",
                default: "Round"
              },
              image: authState?.image ? authState?.image : "no-user",
              onClick: () => {
                authState?.status ?
                navigate("/home", {
                  state:{item: 4}
                })
                :
                navigate("/entry")
              }
            }
          ]}
        />
      </div>
    )
}

export default CodingTool