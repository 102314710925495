import axios from "axios";

import toastMessage from "../../helpers/Toast";

export default class Authentication {

    static isChecking = false;
    static isUserToken = false;
    static isForgotPasswordToken = false;

    static async check() {

        if (this.isChecking) {
            return; 
        }
        this.isChecking = true; 

        try {

            let token = localStorage.getItem("accessToken");
                    
            let response = await axios.get(
                process.env.REACT_APP_SERVER_URL + '/users/auth', 
                {
                    headers: {
                        accessToken: token
                    }
                }
            );

            if(response.data && response.data.error) {
                // toastMessage(response.data.error, "error");
                return;
            }

            if(response.data.token) {
                localStorage.setItem("accessToken", response.data.token);
            }

            return response.data;
    
        } catch(error) {
            console.error("Error during check:", error);
        } finally {
            this.isChecking = false;
        }
    }

    static async login(data) {

        let response = await axios.post(
            process.env.REACT_APP_SERVER_URL + '/users/login', 
            data
        )

        if(response.data && response.data.error) {
            toastMessage(response.data.error, "error");
            return;
        }

        if(response.data) {
            toastMessage("Logged In", "success") 
            localStorage.setItem("accessToken", response.data.token)
        }

        return response.data;
    }

    static async verifyCreateAccountToken(token) {

        if(this.isUserToken) {
            return;
        }
        this.isUserToken = true;

        try {
            
            let response = await axios.get(process.env.REACT_APP_SERVER_URL + "/users/token", {
                headers: {
                    userToken: token
                }
            });
        
            if(response?.data && response?.data?.error) {
                if(response?.data?.error?.name === "TokenExpiredError") {
                    toastMessage("Your Sign Up Token Has Expired. Get A New Token in the Sign Up Page.","error")
                }
            }
    
            if(response?.data && response?.data?.exists) {
                toastMessage("You Already Have an Account. Try Forgot Password", "error");
            }
            
            return response?.data;

        } catch(error) {
            console.error("Error", error);
        } finally {
            this.isUserToken = false;
        }
    }

    static async verifyForgotPasswordToken(token) {

        if(this.isForgotPasswordToken) {
            return;
        }
        this.isForgotPasswordToken = true;

        try {
            
            let response = await axios.get(process.env.REACT_APP_SERVER_URL + "/users/forgotPasswordToken", {
                headers: {
                    forgotPasswordToken: token
                }
            });
        
            if(response?.data && response?.data?.error) {
                if(response?.data?.error?.name === "TokenExpiredError") {
                    toastMessage("Your Forgot Password Token Has Expired. Get A New Token in the Login Page Page.","error")
                }
            } else if(response?.data && response?.data?.exists == false) {
                toastMessage("You Do Not Have An Account. Try Forgot Password", "error");
            }
            
            return response?.data;

        } catch(error) {
            console.error("Error", error);
        } finally {
            this.isUserToken = false;
        }
    }
}