import React, {useEffect, useState} from 'react'
import {useParams, useNavigate} from "react-router-dom";

import toastMessage from "../helpers/Toast";
import Validation from "../helpers/Validation"
import Authentication from '../services/server/Authentication';
import UserService from "../services/server/Users";

import Input from "./components/Input";
import LandingPageBackground from './components/entry/LandingPage/LandingPageBackground';
import Loading from './components/atoms/Loading';

function ResetPassword() {

    let { id } = useParams();
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState("");
    const [passwordTwo, setPasswordTwo] = useState("")
    const [passwordTwoError, setPasswordTwoError] = useState("");

    useEffect(() => {

        async function fetchData() {    
            
            let response = await Authentication.verifyForgotPasswordToken(id);

            if(response?.error) {
                navigate("/entry");
            } else if(response?.exists == false) {
                navigate("/entry", {
                    state: {
                        step: 1
                    }
                });
            } else if(response?.exists == true) {
                setUser(response.user);
                setLoading(false);
            }
        }

        fetchData();

    }, [])

    const onSetPassword = (value) => {
        setPassword(value);
        if(passwordTwo) {
            onSetPasswordTwo(passwordTwo, value);
        }

        let error = Validation.checkValidPassword(value);
        setPasswordError(error);
    }

    const onSetPasswordTwo = (value, passwordOne=null) => {
        setPasswordTwo(value);

        let error = Validation.checkValidPasswordTwo(passwordOne, value);
        setPasswordTwoError(error);
    }

    const onSubmit = async (e) => {

        e.preventDefault();

        let firstPasswordError = Validation.checkValidPassword(password);
        setPasswordError(firstPasswordError);

        let secondPasswordError = Validation.checkValidPasswordTwo(password, passwordTwo);
        setPasswordTwoError(secondPasswordError);


        let passwordValid = !firstPasswordError && !secondPasswordError;
        if(!passwordValid) {
            toastMessage("Fix Errors with Current Inputs", "error");
            return;
        }

        if(user && passwordValid) {

            let data = {
                id: user.id,
                password: password,
            }

            let response = await UserService.updatePassword(data);

            if(response?.error || response?.message) {
                navigate("/entry", {
                    state: {
                        step: 1
                    }
                });
            } 
        }
    }

    if(loading) {
        return <Loading type={"page"} />
    }

    return (
        <div className='Page central'>
            <LandingPageBackground />
            <form className="PageForm Form" onSubmit={(e) => onSubmit(e)}>
                <h2 className='black tertiaryFont'>Reset Password</h2>
                <div className='fields'>
                    <Input 
                        tabIndex="1" 
                        value={password} 
                        setValue={(value) => onSetPassword(value)} 
                        error={passwordError}
                        placeholder={"Enter your Password"} 
                        label={"Password"}
                        secret
                    />
                    <Input 
                        tabIndex="2" 
                        value={passwordTwo} 
                        setValue={(value) => onSetPasswordTwo(value, password)} 
                        error={passwordTwoError}
                        placeholder={"Re-Enter your Password"} 
                        label={"Confirm Password"}
                        secret
                    />
                </div>
                <button 
                    className="button-quart" 
                    onClick={(e) => onSubmit(e)}
                >
                    <h3 className='normal tertiaryFont'>Reset</h3>
                </button>
            </form>
        </div>
    )
}

export default ResetPassword