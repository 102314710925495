import React, {useState, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../helpers/AuthContext';
import Input from "./Input";

function NewFile(props) {

    const navigate = useNavigate();
    const [fileName, setFileName] = useState("");
    const {authState} = useContext(AuthContext);

    const onSubmit = (e) => {

        e.preventDefault();
        if(authState.status) {
            props.setFileName(fileName);
        } else {
            navigate("/entry");
        }
    }

    return (
        <div className='Container Form justForm'>
            <div className='fields'>
                <Input tabIndex="1" value={fileName} setValue={setFileName} placeholder={"Enter Filename"} />
            </div>
            <div className='submission'>
                <button tabIndex="2" onClick={(e) => onSubmit(e)}>
                    <h4 className='noMargin'>Create File</h4>
                </button>
            </div>
        </div>
    )
}

export default NewFile