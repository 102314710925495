import React, {useEffect, useState, useRef, useContext} from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { AuthContext } from '../helpers/AuthContext';
import ProjectFavorites from '../services/server/ProjectFavorites';
import Projects from '../services/server/Projects';
import ProjectUserPlays from '../services/server/ProjectUserPlays';

import Preview from "./components/Preview"
import Footer from "./components/Footer"
import Popup from './components/Popup';
import PreventRefresh from './components/atoms/PreventRefresh';

import toastMessage from "../helpers/Toast";

import HomeIcon from '../assets/svg/Navigation/Home-active.svg';
import Favorite from "../assets/svg/Navigation/Favorite-active.svg";
import Comment from "../assets/svg/Navigation/Comment-active.svg";
import Refresh from "../assets/svg/Navigation/Refresh.svg";
import Info from "../assets/svg/Navigation/Info-active.svg";

import HomeInactive from "../assets/svg/Navigation/Home-inactive.svg";
import CommentInactive from "../assets/svg/Navigation/Comment-inactive.svg";
import InfoInactive from "../assets/svg/Navigation/Info-inactive.svg";
import FavoriteInactive from "../assets/svg/Navigation/Favorite-inactive.svg";

import Cache from "../services/Cache";
import Loading from "./components/atoms/Loading"

function Game() {

    const navigate = useNavigate();
    const {state} = useLocation();
    const {entityId} = useParams();
    const {authState} = useContext(AuthContext);
    const [refresh, setRefresh] = useState(0);
    const [project, setProject] = useState({});
    const [favorite, setFavorite] = useState({favorite: false});
    const [display, setDisplay] =  useState(false);

    const nextProjectsRef = useRef(null);
    const startDate = useRef(null);

    useEffect(() => {
      const handleBeforeUnload = (event) => {
        onPlayEnd();
      };

      window.addEventListener('unload', handleBeforeUnload);

      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('unload', handleBeforeUnload);
      };
    }, []);

    useEffect(() => {      
      setDisplay(false);
      startDate.current = Date.now();

      async function fetchData() {
        let project = await Projects.getProjectByEntityId(entityId);
        setProject(project);
        if(project.projectFavorites?.length === 1) {
          setFavorite({...favorite, favorite: project.projectFavorites[0].favorite, id: project.projectFavorites[0].id})
        } else {
          setFavorite({favorite: false});
        }
        
        if(authState.status) {
          if(!state) {
            fetchProjectArray();
          } else {
            nextProjectsRef.current = state.next;
            if(state.next.length === 0) {
              fetchProjectArray();
            }
          }
        }
      }

      fetchData();

    }, [entityId])

    async function fetchProjectArray() {
      let entityIds = {...((state && state.previous) ? {entityIds: [entityId, ...state.previous]} : {entityIds: [entityId]})};
      let projects = await Projects.getRecommendedProjectsId(entityIds);
      if(projects?.length >= 1) {
        nextProjectsRef.current = projects.map((value) => value.entityId);
      }
    }

    const onPlayEnd = async () =>{ 
      if(startDate.current && (Date.now() - startDate.current)/1000 >= 15) {
        await ProjectUserPlays.createProjectUserPlay({
          startDate: startDate.current,
          endDate: Date.now(),
          projectEntityId: entityId,
          ...(authState ? {userId: authState.userId} : {})
        })
      }
    }

    const favoriteClicked = async () => {
      Cache.deleteCache(entityId);
      let data = {
        favorite: !favorite.favorite,
        userId: authState.id,
        projectId: project.id,
        ...(favorite.id ? {id: favorite.id} : {})
      }
      if(favorite.id) {
        await ProjectFavorites.updateProjectFavorites(data) 
        setFavorite({...favorite, favorite: !favorite.favorite});
      } else {
        let response = await ProjectFavorites.createProjectFavorites(data);
        setFavorite({...favorite, id: response.message.id, favorite: !favorite.favorite})
      }
    }

  const onSwipeUp = async () => {
    if(nextProjectsRef.current && nextProjectsRef.current[0]) {
      onPlayEnd();
      setProject(null);
      navigate("/game/" + nextProjectsRef.current[0], 
      {
        state :{
          previous: (state && state.previous) ? [...state.previous, entityId] : [entityId],
          next: nextProjectsRef.current.shift() ? nextProjectsRef.current : [],
        }
      })
    } else {
      toastMessage("You have played all the games", "error");
    }
  }

  const onSwipeDown = async () => {
    if(state && state.previous && state.previous.length-1 >= 0) {
      onPlayEnd();
      setProject(null);
      navigate("/game/" + state.previous[state.previous.length-1], 
      {
        state: {
          previous: state.previous.slice(0, -1),
          next: [entityId, ...nextProjectsRef.current]
        }
      })
    } else {
      onSwipeUp();
      // generate random games without any of the ones played before or next
    }
  }

  if(!project)
    return <Loading type="page" />
  
  return (
    <PreventRefresh>
      <div className='Page column NoRefresh'>
      {display === "comment" && 
      <Popup 
        type="comments"
        title={"Comments"}
        setDisplay={(value) => setDisplay(value)}
        projectId={project.id}
        />}
      {
        display === "info" && 
        <Popup 
          type={"info"}
          title={project.name}
          height={"fit-content"}
          setDisplay={(value) => setDisplay(value)}
          projectId={project.id}
          createdAt={project.createdAt}
          developer={project.user}
          plays={project?.projectUserPlays?.length}
          thumbnail={project.image}
        />
      }
      <Preview refresh={refresh} scripts={project.files}/>
      <Footer
          default = {-1}
          items = {[
            {
              name: 'home',
              displayText: 'Home',
              style: {
                active: null,
                default: 'Icon-Reduced'
              },
              icon: {
                active: HomeIcon,
                inactive: HomeInactive
              },
              onClick: () => {
                onPlayEnd();
                  navigate("/home", 
                  {
                    state: {
                      item: 0,
                    }
                  })
              } 
            },
            {
              name: 'favorite',
              displayText: 'Favorite',
              style: {
                active: null,
                default: 'Icon'
              },
              icon: {
                active: favorite.favorite ? Favorite : FavoriteInactive,
                inactive: favorite.favorite ? Favorite : FavoriteInactive
              },
              onClick: () => {
                if(authState.status) {
                  favoriteClicked()
                } else {
                  toastMessage("Create An Account to Favorite", "success")
                  navigate("/entry");
                }
              }
            },
            {
              name: 'refresh',
              style: {
                active: null,
                default: 'Icon-Large'
              },
              icon: {
                active: Refresh,
                inactive: Refresh
              },
              onClick: () => setRefresh(prevValue => prevValue + 1),
              swipeButton: true,
              swipeUp: () => {
                if(authState.status)  {
                  onSwipeUp()
                } else {
                  toastMessage("Create An Account to Play More Games", "success")
                  navigate("/entry"); 
                }
              },
              swipeDown: () => {
                if(authState.status)  {
                  onSwipeDown()
                } else {
                  toastMessage("Create An Account to Play More Games", "success")
                  navigate("/entry"); 
                }
              },
            },
            {
              name: 'comment',
              displayText: 'Comment',
              style: {
                active: null,
                default: 'Icon'
              },
              icon: {
                active: display === "comment" ? Comment : CommentInactive,
                inactive: display === "comment" ? Comment : CommentInactive
              },
              onClick: () => {
                if(authState.status) {
                  display === "comment" ? setDisplay(false) : setDisplay("comment")
                } else {
                  toastMessage("Create An Account to Comment", "success")
                  navigate("/entry"); 
                }
              }
            },
            {
              name: 'info',
              displayText: "Info",
              style: {
                active: null,
                default: "Icon"
              },
              icon: {
                active: display === "info" ? Info : InfoInactive,
                inactive: display === "info" ? Info : InfoInactive,
              },
              onClick: () => {
                if(authState.status) {
                  display === "info" ? setDisplay(false) : setDisplay("info")
                } else {
                  toastMessage("Create An Account to View Game Info", "success")
                  navigate("/entry"); 
                }
              }
            }
          ]}
        /> 
      </div>
    </PreventRefresh>
  )
}

export default Game