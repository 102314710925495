import React, {useState, useRef, useEffect} from 'react'

// SVGs
import View from "../../assets/svg/view.svg";
import Hide from "../../assets/svg/hide.svg";
import Valid from "../../assets/svg/Valid.svg";
import Invalid from "../../assets/svg/Invalid.svg"
import { Tooltip } from 'react-tooltip';
function Input(props) {

    const [show, setShow] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const inputRef = useRef(null);

    const { value, 
            placeholder, 
            tabIndex, 
            setValue,
            label, 
            secret, 
            action, 
            actionText, 
            disabled, 
            autoComplete,
            error,
            endElement,
            name
    } = props;

    useEffect(() => {
        if(inputRef.current) {
            inputRef.current.addEventListener('keydown', handleKeyDown);
        }

        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);


        return () => {
            if(inputRef.current) {
                inputRef.current.removeEventListener('keydown', handleKeyDown);
            }
            window.removeEventListener('resize', checkMobile);
        };
    }, []);

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            if(props.onEnter)
                props.onEnter();
        }
    };

    return (
        <div className='field'>
            {
                label &&
                <div className='fieldLabel'>
                    <h4 className='noMargin tertiaryFont'>{label}</h4>
                </div>
            }
            <div className='inputContainer'>
                <input 
                    name={name}
                    ref={inputRef}
                    type={secret ? show ? "text" : "password" : "text"} 
                    tabIndex={tabIndex} 
                    value={value} 
                    placeholder={placeholder} 
                    autoComplete={autoComplete}
                    onChange={(e) => setValue(e.target.value)} 
                    disabled={disabled} 
                    style={ props.background ? {background: props.background} : {}}
                />
                {endElement?.status && 
                    <div 
                        className={`statusIcon ${endElement?.status}`}
                        data-tooltip-id="my-tooltip" 
                        data-tooltip-content={endElement?.tooltip?.text}
                        data-event="click hover" // Allow both click and hover events
                        onClick={(endElement?.tooltip && isMobile) ? (e) => e.stopPropagation() : null} 
                        >
                        {endElement?.status === 'default' && <div className='default'></div>}
                        {endElement?.status === 'loading' && <div className="loader"></div>}
                        {endElement?.status === 'error' && <img className={"validIcon"} src={Invalid} alt="Invalid"/>}
                        {endElement?.status === 'valid' && <img className={"validIcon"} src={Valid} alt="Valid"/>}
                        {endElement?.tooltip?.text && <Tooltip id="my-tooltip" />}
                    </div>
                }
                
                {secret && <img className="secret" alt="icon" src={show ? View : Hide} onClick={() => setShow(!show)}/>}
            </div>
            {action && <p className="action normal" onClick={() => action()}>{actionText}</p>}
            {error && <p className="inputError normal" >* {error}</p>}
        </div>
    )
}

export default Input