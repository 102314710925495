import React, {useEffect, useState, useContext, useCallback} from 'react'
import {useParams, useNavigate, useLocation} from "react-router-dom";

import toastMessage from "../helpers/Toast";
import Validation from "../helpers/Validation"
import UserService from "../services/server/Users"
import { AuthContext } from '../helpers/AuthContext'; 
import Cache from "../services/Cache";
import Authentication from '../services/server/Authentication';

import Input from './components/Input';
import LandingPageBackground from './components/entry/LandingPage/LandingPageBackground';
import CheckboxInput from './components/atoms/CheckboxInput';
import Loading from './components/atoms/Loading';

function CreateAccount() {

    let { id } = useParams();
    const navigate = useNavigate();
    const {setAuthState} = useContext(AuthContext);
    const location = useLocation();
    const { state } = location;
    const [loading, setLoading] = useState(true);
    const [username, setUsername] = useState( state?.username || "");
    const [usernameError, setUsernameError] = useState("");
    const [usernameUnique, setUsernameUnique] = useState("default");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState( state?.password || "")
    const [passwordError, setPasswordError] = useState(""); 
    const [passwordTwo, setPasswordTwo] = useState(state?.passwordTwo || "")
    const [passwordTwoError, setPasswordTwoError] = useState("")
    const [termsPolicyAccepted, setTermsPolicyAccepted] = useState(state?.termsPolicyAccepted || false);
    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(state?.privacyPolicyAccepted || false);

    useEffect(() => {

        async function fetchData() { 

            let response = await Authentication.verifyCreateAccountToken(id);

            if(response?.error) {
                navigate("/entry");
            } else if(response?.exists) {
                navigate("/entry", {
                    state: {
                        step: 1
                    }
                });
            } else if(!response?.exists) {
                setEmail(response?.user?.email);
            }
        }

        fetchData();

    }, [id])

    useEffect(() => {
        if(email) {
            setLoading(false);
        }
    }, [email])

    useEffect(() => {
        if(!username) {
            setUsernameUnique("default")
        }
    }, [username])

    const debouncedCheckUniqueUsername = useCallback(
        Validation.debounce(async (value, error) => {
            if(!value) {
                return;
            } else if(error) {
                setUsernameUnique("error");
                return;
            }
            let exists = await UserService.checkUniqueUsername(value);
            setUsernameUnique(exists ? "error" : "valid");
        }, 1000),
        []
    );
    
    async function onSetUsername(value) {
        setUsername(value);
        let error = Validation.checkValidUsername(value);
        setUsernameError(error);
        debouncedCheckUniqueUsername(value, error);

        if(error) {
            setUsernameUnique("error");
        } else if(value) {
            setUsernameUnique("loading");
        } else {
            setUsernameUnique("default")
            setUsernameError("")
        }
    }

    const onSetPassword = (value) => {
        setPassword(value);
        if(passwordTwo) {
            onSetPasswordTwo(passwordTwo, value)
        }

        let error = Validation.checkValidPassword(value);
        setPasswordError(error);
    }
    
    const onSetPasswordTwo = (value, passwordOne=null) => {
        setPasswordTwo(value);
        
        let error = Validation.checkValidPasswordTwo(passwordOne, value);
        setPasswordTwoError(error);
    }
    
    const onSubmit = async (e) => {
        
        e.preventDefault();
        
        let usernameValid = !usernameError && usernameUnique === "valid";

        let firstPasswordError = Validation.checkValidPassword(password);
        setPasswordError(firstPasswordError);

        let secondPasswordError = Validation.checkValidPasswordTwo(password, passwordTwo);
        setPasswordTwoError(secondPasswordError);
        
        let passwordValid = !firstPasswordError && !secondPasswordError;
        
        if(!usernameValid || !passwordValid) {
            toastMessage("Fix Errors with Current Inputs", "error");
            return;
        }

        let usernameExists = await UserService.checkUniqueUsername(username);

        if(usernameValid && usernameExists) {
            toastMessage(`An Account with the username "${username}" already exists!`, "error");
            return;
        }

        if(!privacyPolicyAccepted || !termsPolicyAccepted) {
            toastMessage(`You Must Accept the Terms of Service and the Privacy Policy to use WanderBuilds`, "error");
            return;  
        }

        if(email && (usernameValid && !usernameExists) && passwordValid) {

            let data = {
                email: email,
                username: username,
                password: password,
                privacyConsent: privacyPolicyAccepted,
                termsOfServiceConsent: termsPolicyAccepted
            }

            const createdUser = await UserService.createUser(data);

            if(createdUser) {
                setAuthState({
                    username: createdUser.username, 
                    id: createdUser.id, 
                    status: true, 
                    createdAt: createdUser.createdAt, 
                    image: createdUser.image, 
                    subscription: createdUser.subscription
                });
                Cache.deleteAllCache();
                navigate('/home')
            } 
        }
    }

    if(loading) {
        return <Loading type={"page"} />
    }

    return (
        <div className='central' style={{minHeight: "100vh"}}>
            <LandingPageBackground />
            <form className="PageForm Form" onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <h2 className='black tertiaryFont'>Create Account</h2>
                <div className='fields'>
                    <input type="text" style={{ display: 'none' }} />
                    <input type="password" style={{ display: 'none' }} />
                    <Input 
                        tabIndex="1" 
                        value={email} 
                        placeholder={"Enter your Email"} 
                        label={"Email"}
                        disabled
                    />
                    <Input 
                        tabIndex="2" 
                        value={username} 
                        setValue={(value) => onSetUsername(value)} 
                        error={usernameError}
                        placeholder={"Enter your Username"}
                        label={"Username"}
                        autoComplete={"off"}
                        endElement={
                            {
                                ...(usernameUnique === "error" &&
                                    {
                                        tooltip: {
                                            background: "red",
                                            text: "Username Cannot Be Used"
                                        }
                                    } 
                                ),
                                ...(usernameUnique === "valid" &&
                                    {
                                        tooltip: {
                                            background: "green",
                                            text: "Username is Available"
                                        }
                                    } 
                                ),
                                status: usernameUnique
                            }
                        }
                        name={"one"}
                    />
                    <Input 
                        tabIndex="3" 
                        value={password} 
                        setValue={(value) => onSetPassword(value)} 
                        error={passwordError}
                        placeholder={"Enter your Password"} 
                        label={"Password"}
                        autoComplete={"new-username"}
                        name={"two"}
                        secret
                    />
                    <Input 
                        tabIndex="4" 
                        value={passwordTwo} 
                        setValue={(value) => onSetPasswordTwo(value, password)}
                        error={passwordTwoError} 
                        placeholder={"Re-Enter your Password"} 
                        label={"Confirm Password"}
                        autoComplete={"new-password"}
                        name={"three"}
                        secret
                    />
                    <CheckboxInput 
                        setCheckbox={(value) => setTermsPolicyAccepted(value)} 
                        checkbox={termsPolicyAccepted}
                        link={"/terms-of-service"}
                        title={"Terms of Service"}
                        username={username}
                        password={password}
                        passwordTwo={passwordTwo}
                        termsPolicyAccepted={termsPolicyAccepted}
                        privacyPolicyAccepted={privacyPolicyAccepted}
                        prevUrl={`/createAccount/${id}`}
                    />
                    <CheckboxInput 
                        setCheckbox={(value) => setPrivacyPolicyAccepted(value)} 
                        checkbox={privacyPolicyAccepted}
                        link={"/privacy-policy"}
                        title={"Privacy Policy"}
                        username={username}
                        password={password}
                        passwordTwo={passwordTwo}
                        termsPolicyAccepted={termsPolicyAccepted}
                        privacyPolicyAccepted={privacyPolicyAccepted}
                        prevUrl={`/createAccount/${id}`}
                    />
                </div>
                <button className="button-quart" onClick={(e) => onSubmit(e)}><h3 className='normal tertiaryFont'>Create</h3></button>
            </form>
        </div>
    )
}

export default CreateAccount