const CODE_SNIPPET = {
    basic: `function setup() {
  createCanvas(windowWidth, windowHeight, WEBGL);  
}

function draw() {
  background(173,216,230);
  orbitControl();  
  sphere(150)
}`
}

module.exports = { CODE_SNIPPET}