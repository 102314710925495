import axios from "axios";

import toastMessage from "../../helpers/Toast";

import Cache from "../Cache";

export default class Projects {

    static async getProjects() {
        try {
            // Check if the result is already cached
            let cacheStatus = Cache.checkCached("projects");
            if(cacheStatus) {
                return cacheStatus;
            }

            let response = await axios.get(
                process.env.REACT_APP_SERVER_URL + "/projects",
                { headers: {
                        accessToken: localStorage.getItem("accessToken")
                    } 
                }
            )

            if(response.data && response.data.error) {
                return;
            }

            Cache.setCache("projects", response.data);
        
            return response.data;
        } catch(error) {
            alert(error);
        }
    }

    static async getProject(id) {

        try {
            let response = await axios.get(
                process.env.REACT_APP_SERVER_URL + "/projects/" + id, 
                { headers: {
                        accessToken: localStorage.getItem("accessToken")
                    } 
                }, 
            )

            if(response.data && response.data.error) {
                toastMessage(response.data.error, "error");
                return;
            }
        
            return response.data;
        } catch(error) {
            alert(error);
        }
    }


    static async getProjectByEntityId(entityId) {

        try {
            // Check if the result is already cached
            let cacheStatus = Cache.checkCached(entityId);
            if(cacheStatus) {
                return cacheStatus;
            }

            let response = null;

            if(localStorage.getItem("accessToken")) {
                response = await axios.get(
                    process.env.REACT_APP_SERVER_URL + "/projects/entityId/" + entityId, 
                    {
                        headers: {
                            accessToken: localStorage.getItem("accessToken"),
                        } 
                    }, 
                )
            } else {
                response = await axios.get(
                    process.env.REACT_APP_SERVER_URL + "/public/project/" + entityId, 
                )
            }
    
            
            if(response.data && response.data.error) {
                toastMessage(response.data.error, "error");
                return;
            }
    
            Cache.setCache(entityId, response.data);
        
            return response.data;
        } catch(error) {
            alert(error);
        }
    }

    static async getRecommendedProjectsId(entityIds) {
        
        try { 
            let response = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/projects/recommended/entityIds",
                entityIds, 
                { headers: {
                        accessToken: localStorage.getItem("accessToken")
                    } 
                }, 
            )
            
            if(response.data && response.data.error) {
                toastMessage(response.data.error, "error");
                return;
            }
            
                
            return response.data;
        } catch(error) {
            alert(error);
        }
    }

    static async getProjectsByUser(id) {

        try {
            let response = await axios.get(
                process.env.REACT_APP_SERVER_URL + "/projects/user/" + id, 
                { headers: {
                        accessToken: localStorage.getItem("accessToken")
                    } 
                }, 
            )
    
            if(response.data && response.data.error) {
                toastMessage(response.data.error, "error");
                return;
            }
        
            return response.data;
        } catch(error) {
            alert(error);
        }
    }

    static async getProjectsFavoritedByUser() {

        try {
            let response = await axios.get(
                process.env.REACT_APP_SERVER_URL + "/projects/favorited/user", 
                { headers: {
                        accessToken: localStorage.getItem("accessToken")
                    } 
                }, 
            )
    
            if(response.data && response.data.error) {
                toastMessage(response.data.error, "error");
                return;
            }
        
            return response.data;
        } catch(error) {
            alert(error);
        }
    }

    static async createProject(data) {

        try {
            let response = await axios.post(
                process.env.REACT_APP_SERVER_URL + "/projects", 
                data,
                { headers: {
                        accessToken: localStorage.getItem("accessToken")
                    } 
                }, 
            )
    
            if(response.data && response.data.error) {
                toastMessage(response.data.error, "error");
                if(response.data.membership)
                    return response.data;
                return;
            }
        
            return response.data;
        } catch(error) {
            alert(error);
        }
    }

    static async updateProject(data) {

        try {
            let response = await axios.patch(
                process.env.REACT_APP_SERVER_URL + "/projects", 
                data,
                { headers: {
                        accessToken: localStorage.getItem("accessToken")
                    } 
                }, 
            )
    
            if(response.data && response.data.error) {
                toastMessage(response.data.error, "error");
                if(response.data.membership)
                    return response.data;
                return;
            }
        
            return response.data;
        } catch(error) {
            alert(error);
        }
    }

    
    static async delete(id) {

        try {
            await axios.delete(
                process.env.REACT_APP_SERVER_URL + "/projects/" + id,
                { headers: {
                        accessToken: localStorage.getItem("accessToken")
                    } 
                },
            )

            toastMessage("Successfully Deleted Project", "success");
        } catch(e) {
            toastMessage("Error Deleting Project", "error");
        }
    }
}
